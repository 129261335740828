<template>
    <!-- eslint-disable -->
  <section>
    <div class="is-flex is-align-items-center">
      <slot name="heading" />
      <b-button class="ml-5" icon-left="pen" size="is-small" @click="edit = !edit">Edit</b-button>
    </div>
    <form @submit.prevent="submit" class="mb-5">
      <fieldset>
        <legend>Credentials</legend>
        <b-field label="Username">
          <b-input
              placeholder="AfricaTalking App username"
              type="text"
              custom-class="custum-input"
              v-model="form.username"
              :disabled="!edit"
              >
          </b-input>
        </b-field>
        <b-field label="API key" >
          <b-input
              placeholder="AfricaTalking API key"
              custom-class="custum-input"
              v-model="form.apiKey"
              :disabled="!edit"
              >
          </b-input>
        </b-field>
      </fieldset>

      <fieldset>
        <legend>Voice</legend>
        <b-field label="Phone number">
            <b-input
                placeholder="AfricaTalking issued phone number"
                custom-class="custum-input"
                v-model="form.phoneNumber"
                :disabled="!edit"
                >
            </b-input>
        </b-field>

        <fieldset>
          <legend>Inbound Call Forwarding</legend>
          <b-field  label="Forward call to:">
            <b-input
                placeholder="Phone number"
                custom-class="custum-input"
                v-model="form.forwardInboundCallTo"
                :disabled="!edit"
                >
            </b-input>
          </b-field>
          <b-field label="Prompt">
            <b-input
                type="textarea"
                minlength="10"
                maxlength="100"
                placeholder="Prompt before forwarding call"
                v-model="form.inboundCallForwardPrompt"
                :disabled="!edit">
            </b-input>
          </b-field>
        </fieldset>

        <fieldset>
          <legend>Voicemail</legend>
          <b-field label="Prompt">
            <b-input type="textarea"
                minlength="10"
                maxlength="100"
                placeholder="Prompt before recording"
                v-model="form.inboundCallVoicemailPrompt"
                :disabled="!edit">
            </b-input>
          </b-field>
        </fieldset>

        <fieldset>
          <legend>Handle Inbound call</legend>
          <b-field label="During business hour">
            <b-radio v-model="form.businessHourCall"
                name="business-hour-call"
                native-value="forward"
                type="is-primary"
                :disabled="!edit">
                Forward
            </b-radio>
            <b-radio v-model="form.businessHourCall"
                name="business-hour-call"
                native-value="voicemail"
                type="is-primary"
                :disabled="!edit">
                Voicemail
            </b-radio>
          </b-field>
          <b-field label="Outside business hour">
            <b-radio v-model="form.outsideBusinessHourCall"
                name="outside-business-hour-call"
                native-value="forward"
                type="is-primary"
                :disabled="!edit">
                Forward
            </b-radio>
            <b-radio v-model="form.outsideBusinessHourCall"
                name="outside-business-hour-call"
                native-value="voicemail"
                type="is-primary"
                :disabled="!edit">
                Voicemail
            </b-radio>
          </b-field>
        </fieldset>
      </fieldset>

      <fieldset>
        <legend>SMS</legend>
        <b-field label="From">
            <b-input
                placeholder="AfricaTalking SMS From"
                custom-class="custum-input"
                v-model="form.smsFrom"
                :disabled="!edit"
                >
            </b-input>
        </b-field>
      </fieldset>
      <slot name="actions" v-bind="{ submit }">
        <div class="columns is-centered" v-if="edit">
            <div class="column">
                <b-button
                    expanded
                    @click="edit = false"
                    class="has-text-weight-bold has-text-primary"
                >
                    Cancel
                </b-button>
            </div>
            <div class="column">
                <b-button
                    type="is-primary"
                    expanded
                    @click="submit"
                    class="has-text-weight-bold"
                >
                    Save
                </b-button>
            </div>
        </div>
      </slot>
    </form>
  </section>

</template>

<script>
/*eslint-disable */
import { set } from '@/api/setting';

export default {
    name: "AfricaTalkingSetting",
    data() {
        return {
            edit: false,
            form: {
                username: null,
                apiKey: null,
                phoneNumber: null,
                smsFrom: null,
                businessHourCall: null,
                outsideBusinessHourCall: null,
                forwardInboundCallTo: null,
                inboundCallForwardPrompt: null,
                inboundCallVoicemailPrompt: null
            }
        };
    },

  props: {
    config: Object,
    editable: Boolean,
    settingsKey: String,
  },

  methods: {
    async submit() {
      const loadingComponent = this.$buefy.loading.open();

      try {
        const { data } = await set(this.form, this.settingsKey);
        this.form = data;
        this.$buefy.toast.open({
          message: 'AfricaTalking setting saved',
          type: 'is-primary',
          position: 'is-bottom',
        });
        this.edit = false;
        this.$emit('saved', this.form);
      }
      catch (error) {
        this.showError(error);
      }
      finally {
        loadingComponent.close();
      }
    },
  },

  watch: {
    config: {
      immediate: true,
      handler(config){
        if(config){
          this.form = config
        }
      }
    },
    editable: {
      immediate: true,
      handler(e) {
          this.edit = e;
      },
  }
  }
};
</script>
