<template>
    <!-- eslint-disable -->
    <section>
        <div class="is-flex is-align-items-center">
            <slot name="heading" />
            <b-button class="ml-5" icon-left="pen" size="is-small" @click="edit = !edit">Edit</b-button>
        </div>
        <form @submit.prevent="submit" class="mb-5">
            <b-field label="Name">
                <b-input
                    placeholder="Organization name"
                    type="text"
                    custom-class="custum-input"
                    v-model="form.name"
                    :disabled="!edit"
                    >
                </b-input>
            </b-field>
            <b-field label="About">
                <b-input
                    type="textarea"
                    minlength="10"
                    maxlength="300"
                    placeholder="About the organization"
                    v-model="form.about"
                    :disabled="!edit">
                </b-input>
            </b-field>
            <b-field label="Timezone">
                <b-select 
                icon="earth"
                v-model="form.timezone"
                placeholder="Select a timezone"
                expanded
                :disabled="!edit"
                >
                    <option
                        v-for="(timezone, i) in timezones"
                        :value="timezone"
                        :key="i">
                        {{ timezone }}
                    </option>
                </b-select>
            </b-field>

            <fieldset>
                <legend>Schedule</legend>
                
                <div class="columns" v-for="day in scheduledays" :key="day">
                    <div class="column">
                        <b-switch v-model="schedule[day].available" 
                        :disabled="!edit">
                            <h4 class="is-capitalized">{{ day }}</h4>
                        </b-switch>
                        
                    </div>
                    <div class="column">
                        <div class="columns">
                            <div class="column">
                                <h4>Opens at</h4>
                                <b-timepicker
                                    v-model="schedule[day].openAt"
                                    :placeholder="`Opening time on ${day}`"
                                    icon="clock"
                                    :disabled="!(edit && schedule[day].available)"
                                    >
                                </b-timepicker>
                            </div>
                            <div class="column">
                                <h4>Closes at</h4>
                                <b-timepicker
                                    v-model="schedule[day].closeAt"
                                    :placeholder="`Closing time on ${day}`"
                                    icon="clock"
                                    :min-time="schedule[day].openAt ? schedule[day].openAt : new Date()"
                                    :disabled="!(edit && schedule[day].available)"
                                    >
                                </b-timepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            
            <slot name="actions" v-bind="{ submit }">
                <div class="columns is-centered" v-if="edit">
                    <div class="column">
                        <b-button
                            expanded
                            @click="edit = false"
                            class="has-text-weight-bold has-text-primary"
                        >
                            Cancel
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            type="is-primary"
                            expanded
                            @click="submit"
                            class="has-text-weight-bold"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
            </slot>
        </form>
    </section>
</template>

<script>
/*eslint-disable */
import moment from 'moment-timezone';
import { set } from '@/api/setting';

export default {
    name: "OrganizationSetting",
    data() {
        return {
            edit: false,
            form: {
                name: '',
                about: '',
                timezone: ''
            },
            schedule: {
                monday: {
                    available: false,
                    openAt: null,
                    closeAt: null,
                },
                tuesday: {
                    available: false,
                    openAt: null,
                    closeAt: null,
                },
                wednesday: {
                    available: false,
                    openAt: null,
                    closeAt: null,
                },
                thursday: {
                    available: false,
                    openAt: null,
                    closeAt: null,
                },
                friday: {
                    available: false,
                    openAt: null,
                    closeAt: null,
                },
                saturday: {
                    available: false,
                    openAt: null,
                    closeAt: null,
                },
                sunday: {
                    available: false,
                    openAt: null,
                    closeAt: null,
                },
            }
        };
    },

    computed: {
        timezones() {
            return moment.tz.names();
        },
        scheduledays() {
            return Object.keys(this.schedule);
        }
    },

    props: {
        config: Object,
        editable: Boolean,
        settingsKey: String,
    },

    methods: {
        async submit() {
            const loadingComponent = this.$buefy.loading.open();
            const schedule = {};
            this.scheduledays.forEach(day => {                    
                schedule[day] = {
                    available: this.schedule[day].available,
                    openAt: moment(this.schedule[day].openAt).format('hh:mm a'),
                    closeAt: moment(this.schedule[day].closeAt).format('hh:mm a'),
                }
            });

            try {
                const { data } = await set({...this.form, schedule }, this.settingsKey);
                this.form = data;
                this.$store.commit('SETTINGS', {
                    organization: data
                }),
                await this.$store.dispatch('getStatus');
                this.$buefy.toast.open({
                message: 'Organization settings saved',
                type: 'is-primary',
                position: 'is-bottom',
                });
                this.edit = false;
                this.$emit('saved', this.form);
            } 
            catch (error) {
                this.showError(error);
            }
            finally {
                loadingComponent.close();
            }
        },

    },

    watch: {
        config: {
            immediate: true,
            handler(config){
                if(config){
                    this.form = config;
                    const schedule = config ? config.schedule : null;
                    if(schedule) {
                        this.scheduledays.forEach(day => {                    
                            this.schedule[day] = {
                                available: this.schedule[day] ?  schedule[day].available : false,
                                openAt: this.schedule[day] ? moment(schedule[day].openAt, 'hh:mm a').toDate()  : moment('09:00 am', 'hh:mm a').toDate(),
                                closeAt: this.schedule[day] ?  moment(schedule[day].closeAt, 'hh:mm a').toDate() : moment('05:00 pm', 'hh:mm a').toDate(),
                            }
                        });
                    }                 
                }
            }
        },
        editable: {
            immediate: true,
            handler(e) {
                this.edit = e;
            },
        },

    }
};
</script>
