<template>
    <!-- eslint-disable -->
    <section>
        <div class="is-flex is-align-items-center">
            <slot name="heading" />
            <b-button class="ml-5" icon-left="pen" size="is-small" @click="edit = !edit">Edit</b-button>
        </div>
        <form @submit.prevent="submit" class="mb-5">
            <b-field label="Public key">
                <b-input
                    placeholder="Paystack public key"
                    type="text"
                    custom-class="custum-input"
                    v-model="form.publicKey"
                    :disabled="!edit"
                    >
                </b-input>
            </b-field>
            <b-field label="Secret key">
                <b-input
                    placeholder="Paystack secret key"
                    custom-class="custum-input"
                    v-model="form.secretKey"
                    :disabled="!edit"
                    >
                </b-input>
            </b-field>

            <slot name="actions" v-bind="{ submit }">
                <div class="columns is-centered" v-if="edit">
                    <div class="column">
                        <b-button
                            expanded
                            @click="edit = false"
                            class="has-text-weight-bold has-text-primary"
                        >
                            Cancel
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            type="is-primary"
                            expanded
                            @click="submit"
                            class="has-text-weight-bold"
                        >
                            Save
                        </b-button>
                    </div>
                </div>
            </slot>
        </form>
    </section>
</template>

<script>
/*eslint-disable */
import { set } from '@/api/setting';
export default {
    name: "PaystackSetting",
    data() {
        return {
            edit: false,
            form: {
                secretKey: '',
                publicKey: ''
            }
        };
    },

    props: {
        config: Object,
        editable: Boolean,
        settingsKey: String,
    },

    methods: {
        async submit() {
            const loadingComponent = this.$buefy.loading.open();

            try {
                const { data } = await set(this.form, this.settingsKey);
                this.form = data;
                this.$buefy.toast.open({
                message: 'Paystack setting saved',
                type: 'is-primary',
                position: 'is-bottom',
                });
                this.edit = false;
                this.$emit('saved', this.form);
            } 
            catch (error) {
                this.showError(error);
            }
            finally {
                loadingComponent.close();
            }
        },
    },

    watch: {
        config: {
            immediate: true,
            handler(config){
                if(config){
                    this.form = config
                }
            }
        },
        editable: {
            immediate: true,
            handler(e) {
                this.edit = e;
            },
        }
    }
};
</script>
